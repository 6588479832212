import {
  ALL
  // APPROVED,
  // ContractRenewal,
  // departure,
  // INTO,
  // ProbationTracking,
  // REJECTED,
  // ROLLOUT,
  // WITHDRAWINTO
} from '@/views/quotaManagement/contain'

export const TableColumn = [
  // {
  //   key: 'hcId',
  //   name: 'HC ID',
  //   dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
  //   visible: true, // 是否展示在表格列中
  //   sortable: false, // 是否可以排序
  //   fixed: false, // 固定列的位置(left, right)
  //   width: 61 // 默认宽度，像素
  // },
  {
    key: 'jobTitle',
    name: 'Job Title',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: false, // 固定列的位置(left, right)
    width: 100 // 默认宽度，像素
  },
  {
    key: 'hfmCode',
    name: 'HFM Code',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 150
  },
  {
    key: 'level2',
    name: 'Level2',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'level3',
    name: 'Level3',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },

  {
    key: 'hrbpOwner',
    name: 'HRBP Owner',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 130
  },
  {
    key: 'annualTotalCost',
    name: 'Annual Total Cost',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 240
  },
  {
    key: 'hcStatus',
    name: 'HC Status',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 150
  },

  {
    key: 'hcNature',
    name: 'HC Nature',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 130
  },
  {
    key: 'hcSubNature',
    name: 'HC Sub Nature',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 130
  },
  {
    key: 'hcType',
    name: 'HC Type',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 130
  },
  {
    key: 'fteName',
    name: 'FTE Name',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 130
  },
  {
    key: 'leaverName',
    name: 'Leaver Name',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 130
  }
]
// export const departureTableColumn = [
//   {
//     key: 'jobTitle',
//     name: 'Job Title',
//     dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
//     visible: true, // 是否展示在表格列中
//     sortable: false, // 是否可以排序
//     fixed: false, // 固定列的位置(left, right)
//     width: 100 // 默认宽度，像素
//   },
//   {
//     key: 'department',
//     name: 'Department',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 220
//   },
//   {
//     key: 'assigmentProjectLevel2',
//     name: 'Assigment/project（Level2)',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 220
//   },
//
//   {
//     key: 'descriptionLevel3',
//     name: 'Description (Level3)',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 200
//   },
//   {
//     key: 'hfmCode',
//     name: 'HFM Code',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 150
//   },
//   {
//     key: 'clientName',
//     name: 'Client',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: false
//   },
//   {
//     key: 'businessUnitName',
//     name: 'Business Unit',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 150
//   },
//   {
//     key: 'hcStatus',
//     name: 'HC Status',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 200
//   },
//   {
//     key: 'candidateName',
//     name: 'Candidate Name',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 200
//   },
//   {
//     key: 'taOwner',
//     name: 'TA Owner',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 100
//   },
//   {
//     key: 'offerStatus',
//     name: 'Offer Status',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 200
//   },
//   {
//     key: 'transferStatus',
//     name: 'Transfer Status',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 200
//   },
//   {
//     key: 'leaveStatus',
//     name: 'Leave Status',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 200
//   }
// ]
// export const ProbationTrackingTableColumn = [
//   {
//     key: 'jobTitle',
//     name: 'Job Title',
//     dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
//     visible: true, // 是否展示在表格列中
//     sortable: false, // 是否可以排序
//     fixed: false, // 固定列的位置(left, right)
//     width: 100 // 默认宽度，像素
//   },
//   {
//     key: 'department',
//     name: 'Department',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 220
//   },
//   {
//     key: 'assigmentProjectLevel2',
//     name: 'Assigment/project（Level2)',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 220
//   },
//
//   {
//     key: 'descriptionLevel3',
//     name: 'Description (Level3)',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 200
//   },
//   {
//     key: 'hfmCode',
//     name: 'HFM Code',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 150
//   },
//   {
//     key: 'clientName',
//     name: 'Client',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: false
//   },
//   {
//     key: 'businessUnitName',
//     name: 'Business Unit',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 150
//   },
//   {
//     key: 'employee',
//     name: 'Employee',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 200
//   },
//   {
//     key: 'feedback',
//     name: 'feedback',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 200
//   },
//   {
//     key: 'taOwner',
//     name: 'TA Owner',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 100
//   },
//   {
//     key: 'offerStatus',
//     name: 'Offer Status',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 200
//   },
//   {
//     key: 'transferStatus',
//     name: 'Transfer Status',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 200
//   },
//   {
//     key: 'probationStatus',
//     name: 'Probation Status',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 200
//   }
// ]
// export const ContractRenewalTableColumn = [
//   {
//     key: 'jobTitle',
//     name: 'Job Title',
//     dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
//     visible: true, // 是否展示在表格列中
//     sortable: false, // 是否可以排序
//     fixed: false, // 固定列的位置(left, right)
//     width: 100 // 默认宽度，像素
//   },
//   {
//     key: 'department',
//     name: 'Department',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 220
//   },
//   {
//     key: 'assigmentProjectLevel2',
//     name: 'Assigment/project（Level2)',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 220
//   },
//
//   {
//     key: 'descriptionLevel3',
//     name: 'Description (Level3)',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 200
//   },
//   {
//     key: 'hfmCode',
//     name: 'HFM Code',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 150
//   },
//   {
//     key: 'clientName',
//     name: 'Client',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: false
//   },
//   {
//     key: 'businessUnitName',
//     name: 'Business Unit',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 150
//   },
//   {
//     key: 'taOwner',
//     name: 'TA Owner',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 100
//   },
//   {
//     key: 'employee',
//     name: 'Employee',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 200
//   },
//   {
//     key: 'feedback',
//     name: 'feedback',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 200
//   },
//   {
//     key: 'offerStatus',
//     name: 'Offer Status',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 200
//   },
//   {
//     key: 'transferStatus',
//     name: 'Transfer Status',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 200
//   },
//   {
//     key: 'renewalStatus',
//     name: 'Renewal Status',
//     dataType: 'string',
//     visible: true,
//     sortable: false,
//     fixed: false,
//     width: 200
//   }
// ]
export const tableObj = {
  [ALL]: TableColumn
  // [APPROVED]: TableColumn,
  // [REJECTED]: TableColumn,
  // [ROLLOUT]: TableColumn,
  // [INTO]: TableColumn,
  // [WITHDRAWINTO]: TableColumn,
  // [departure]: departureTableColumn,
  // [ProbationTracking]: ProbationTrackingTableColumn,
  // [ContractRenewal]: ContractRenewalTableColumn
}
