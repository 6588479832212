<template>
  <div class="quotaRoleButton">
    <div v-if="hcStatus === Approved">
      <el-button
        size="mini"
        role="distribution"
        v-permission
        :disabled="!isButtonDisabled"
        @click="distribution"
        >分配</el-button
      >
      <el-button
        size="mini"
        role="merge"
        v-permission
        @click="merge"
        :disabled="!(isButtonDisabled && isMerge)"
        >合并</el-button
      >
      <el-button
        size="mini"
        role="share"
        v-permission
        @click="approve"
        :disabled="!(isButtonDisabled && isShare)"
        >分摊</el-button
      >
      <el-button
        size="mini"
        role="ta"
        v-permission
        :disabled="!isButtonDisabled"
        @click="sendTA"
        >发送TA</el-button
      >
    </div>
    <div v-else-if="hcStatus === Recruiting">
      <el-button
        size="mini"
        role="distribution"
        v-permission
        :disabled="!isButtonDisabled"
        @click="distribution"
        >分配</el-button
      >
      <el-button
        role="pass"
        :disabled="!isButtonDisabled"
        v-permission
        @click="pass"
        >通过</el-button
      >
      <el-button
        role="rejected"
        :disabled="!isButtonDisabled"
        v-permission
        @click="reject"
        >驳回
      </el-button>
    </div>
    <div v-else>
      <el-button
        size="mini"
        role="distribution"
        v-permission
        :disabled="!isButtonDisabled"
        @click="distribution"
        >分配</el-button
      >
    </div>
    <PersonnelSelectionDialog
      ref="PersonnelSelectionDialogRef"
      @onConfirm="personalOnConfirm"
    />
    <personnelDialogFrom ref="PersonnelDialogFromRef" @onConfirm="onConfirm" />
    <ApportionDialog ref="ApportionDialogRef" @close="close" />
    <ReductionDialog ref="ReductionDialogRef" @close="close" />
  </div>
</template>
<script>
import { ALL, Approved, Recruiting } from '@/views/quotaManagement/contain'
import PersonnelSelectionDialog from '@/components/PersonnelSelectionDialog/PersonnelSelectionDialog.vue'
import personnelDialogFrom from '@/views/personnelRequisition/components/PersonnelDialogFrom.vue'
import ApportionDialog from '@/views/quotaManagement/dialog/ApportionDialog.vue'
import ReductionDialog from '@/views/quotaManagement/dialog/ReductionDialog.vue'
import { getItem, setItem } from '@/utils/storage'
import { saveSelectUser, approve, rejected } from '@/api/quto'
import {
  getMergeData,
  saveMergeData,
  savePerson
} from '@/api/personnelRequisition'

export default {
  props: {
    selectVal: {
      type: Array,
      default: () => []
    },
    hcStatus: {
      type: String,
      default: ''
    }
  },
  components: {
    PersonnelSelectionDialog,
    personnelDialogFrom,
    ApportionDialog,
    ReductionDialog
  },
  computed: {
    isButtonDisabled() {
      return Boolean(this.selectVal.length)
    }
  },
  watch: {
    selectVal: {
      handler() {
        this.isMergeFunc()
        this.isResetFunc()
        this.isShareFunc()
      },
      deep: true
    }
  },
  data() {
    return {
      Approved,
      ALL,
      Recruiting,
      isMerge: false,
      isReset: false,
      isShare: false,
      dialogStatus: ''
    }
  },
  methods: {
    pass() {
      const data = {
        ids: this.selectVal.map((item) => item.id).toString()
      }
      approve(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('通过成功')
          this.$emit('close')
        }
      })
    },
    reject() {
      const data = {
        ids: this.selectVal.map((item) => item.id).toString()
      }
      rejected(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('驳回成功')
          this.$emit('close')
        }
      })
    },
    merge() {
      this.$refs.PersonnelDialogFromRef.show({
        status: 'add',
        position: 'mergeHC',
        id: this.selectVal[0].id,
        selectVal: this.selectVal
      })
    },
    reduction() {
      this.$refs.ReductionDialogRef.show({
        selectVal: this.selectVal
      })
    },
    approve() {
      this.$refs.ApportionDialogRef.show({
        selectVal: this.selectVal
      })
    },
    close() {
      this.$emit('close')
    },
    distribution() {
      setItem('quotaSelectVal__yc', this.selectVal)
      this.dialogStatus = 'quto'
      this.$refs.PersonnelSelectionDialogRef.show({
        position: 'quto'
      })
    },
    sendTA() {
      this.dialogStatus = 'sendTA'
      this.$refs.PersonnelSelectionDialogRef.show({
        position: 'sendTA'
      })
    },
    onConfirm(val) {
      if (val) {
        const data = {
          hcIds: this.selectVal.map((item) => item.hcId),
          ...val
        }
        saveMergeData(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('合并成功')
            this.$refs.PersonnelDialogFromRef.close()
            this.$emit('close')
          }
        })
      }
    },
    personalOnConfirm(val) {
      if (this.dialogStatus === 'quto') {
        const data = {
          userId: val.map((item) => item.id).toString(),
          id: this.selectVal.map((item) => item.id).toString(),
          type: 'distribution'
        }
        saveSelectUser(data).then((res) => {
          if (res && res.code === 200) {
            this.$emit('close')
            this.$message.success('分配成功')
          }
        })
      } else if (this.dialogStatus === 'sendTA') {
        const data = {
          userId: val.map((item) => item.id).toString(),
          id: this.selectVal.map((item) => item.id).toString(),
          type: 'ta'
        }
        saveSelectUser(data).then((res) => {
          if (res && res.code === 200) {
            this.$emit('close')
            this.$message.success('发送TA成功')
          }
        })
      }
    },
    isMergeFunc() {
      if (this.selectVal && this.selectVal.length && this.isCountLengthFunc()) {
        this.isMerge = this.selectVal.every((item) => item.hcType === '原始')
      } else {
        this.isMerge = false
      }
    },
    isShareFunc() {
      this.isShare = this.selectVal.length === 2
    },
    isResetFunc() {
      if (
        this.selectVal &&
        this.selectVal.length &&
        this.selectVal.length === 1
      ) {
        this.isReset = this.selectVal.every((item) => item.hcType === '合并')
      } else {
        this.isReset = false
      }
    },
    isCountLengthFunc() {
      return !!(
        this.selectVal &&
        this.selectVal.length <= 4 &&
        this.selectVal.length >= 2
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.quotaRoleButton {
}
</style>
