<template>
  <el-dialog
    title="人员选择"
    :visible.sync="dialogVisible"
    width="50%"
    top="60px"
    class="NoHcDialogDialog"
    append-to-body
    destroy-on-close
    :before-close="close"
  >
    <div>
      <descriptions :descriptionData="descriptionData" />
    </div>
    <PGTitle title="附件信息">
      <pgUploadFile
        :uploadListCall="uploadListCall"
        :id="id"
        type="Transfer"
        :limit="3"
      />
    </PGTitle>
    <PGTitle title="HC选择">
      <div class="PersonnelSelectionDialog__title">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="Job Title">
            <el-input
              v-model="formInline.jobTitle"
              placeholder="Job Title"
            ></el-input>
          </el-form-item>

          <el-form-item label=" . ">
            <el-button type="primary" @click="onSearch">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <PocTable
        ref="dataTable"
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        hidepage
        @selection-change="handleSelectionChange"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          v-for="item in columnConfig"
          v-if="item.visible"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
        ></el-table-column>
      </PocTable>
      <FormDesigner ref="FormDesignerRef" :formScale="formScale" />
    </PGTitle>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { columnConfig } from './columnConfig.js'
import { getHcList, getHfmCodeList, saveNoHc } from '@/api/organization'
import pgUploadFile from '@/components/pg-uploadFile/pg-uploadFile'
import PGTitle from '@/components/PG-title/PG-title'
import descriptions from '@/components/descriptions/descriptions'
import FormDesigner from '@/components/FormDesigner/FormDesigner'
import { getCodeValue, getUserInfo } from '@/api/quto'
import { mergeDesDataOfPort } from '@/utils/util'

export default {
  name: 'NoHcDialog',
  mixins: [$PCommon.TableMixin],
  components: {
    pgUploadFile,
    PGTitle,
    descriptions,
    FormDesigner
  },
  data() {
    return {
      dialogVisible: false,
      HFMList: [],
      uploadListCall: [],
      loading: false,
      columnConfig,
      id: '',
      selectVal: [],
      tableData: [],
      formInline: {
        jobTitle: ''
      },
      formScale: [
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 8,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'Work Schedules',
                prop: 'workSchedules',
                type: 'select',
                options: [],
                rules: '',
                value: '',
                clearable: true,
                filterable: true,
                placeholder: '请选择',
                change: () => {},
                onClick: () => {}
              }
            },
            {
              span: 8,
              id: Math.random(),
              formData: {
                prop: 'effectiveDate',
                rules: '',
                label: 'Effective Date',
                id: Math.random(),
                type: 'data',
                valueFormat: 'yyyy-MM-dd',
                value: '',
                change: () => {}
              }
            }
          ]
        }
      ],
      descriptionData: [
        {
          title: '基础信息',
          content: [
            {
              label: 'English Name',
              key: 'englishName',
              value: ''
            },
            {
              label: 'Chinese Name',
              key: 'chineseName',
              value: ''
            },
            {
              label: 'Lion ID',
              key: 'lionId',
              value: ''
            },
            {
              label: 'Name of Bank',
              key: 'nameOfBank',
              value: ''
            },
            {
              label: 'Bank Account No.',
              key: 'bankAccountNo',
              value: ''
            }
          ]
        }
      ],
      hcIsBroughtOut: false,
      position: 'Personnel'
    }
  },
  methods: {
    getData() {
      if (this.position === 'Personnel') {
        this.queryApi()
      }
    },
    queryApi() {
      getHfmCodeList().then((res) => {
        if (res && res.code === 200) {
          if (res.data) {
            this.HFMList = res.data
          } else {
            this.tableData = []
          }
        }
      })
      const data = {
        ...this.formInline
      }
      getHcList(data).then((res) => {
        if (res && res.code === 200) {
          if (res.data) {
            this.tableData = res.data
          } else {
            this.tableData = []
          }
        }
      })
      getUserInfo({ id: this.id }).then((res) => {
        if (res && res.code === 200) {
          mergeDesDataOfPort(this.descriptionData, res.data)
          this.uploadListCall = res.data.files
          this.$refs.FormDesignerRef.setValue(res.data)
        }
      })
      getCodeValue().then((res) => {
        if (res && res.code === 200) {
          this.$refs.FormDesignerRef.setOptions(res.data)
        }
      })
    },
    close() {
      this.dialogVisible = false
    },
    handleSelectionChange(val) {
      this.selectVal = val
    },
    show(val) {
      this.id = val.id
      this.getData()
      this.dialogVisible = true
    },
    async submit() {
      let formData = await this.$refs.FormDesignerRef.getValue()
      const data = {
        effectiveDate: formData.effectiveDate,
        hcId: this.selectVal.map((item) => item.hcId).toString(),
        id: this.id,
        workSchedulesId: formData.workSchedules
      }
      saveNoHc(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('接受成功')
          this.close()
        }
      })
    },
    onSearch() {
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.NoHcDialogDialog {
  overflow: hidden;
  :deep(.el-dialog__body) {
    height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .transferStatusContent {
    &__title {
      padding-left: 10px;
      font-size: 15px;
      font-weight: bold;
      border-bottom: 1px solid gainsboro;
      margin-top: 30px;
    }
    &__attachments {
      margin-top: 10px;
      margin-left: 20px;
    }
    &__hc {
      margin-top: 20px;
      margin-left: 20px;
    }
  }
}
</style>
