export const ALL = 'All'
export const Active = 'Active'
export const Deleted = 'Deleted'
//转出
export const Approved = 'Approved'
// 转入
//撤销转入
export const Recruiting = 'Recruiting'

/*
所有、待处理、已退回、转出、转入、撤销转入、离职、试用期跟踪、合同续签对应状态值ALL、APPROVED、REJECTED、TRANSFEROUT、TRANSFERIN、UNTRANSFERIN、LEAVE、PROBATION
 */
