<template>
  <div class="quotaManagementBody">
    <PocCardTable class="quotaManagement topPocCardTable">
      <template v-slot:headerTitle class="title">
        <span class="titleTop"> 配额管理 </span>
      </template>
      <template v-slot:headerGroup>
        <el-button @click="resetButton" class="resetButton">
          <i class="el-icon-refresh-left buttonrefreshSpan"></i
          ></el-button>
        <el-button @click="downloadQuito" role="export" v-permission
        >导出全部</el-button>
        <el-button @click="search" type="primary">查询</el-button>
      </template>
      <PocSlider minheight="70px" btntype="primary">
        <el-form
        :inline="true"
        label-position="top"
        :model="formInline"
        class="quotaManagementBodyForm minWidthInput"
      >
        <el-form-item label="HFM Code">
          <el-select
            v-model="formInline.hfmCode"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in hfmCodeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Level 2">
          <el-select
            v-model="formInline.level2"
            clearable
            filterable
            placeholder="请选择"
            @change="changeLevel2"
          >
            <el-option
              v-for="item in level2List"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Level 3">
          <el-select
            v-model="formInline.level3"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in level3List"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Job Title">
          <el-input
            v-model="formInline.jobTitle"
            clearable
            filterable
            placeholder="Job Title"
          ></el-input>
        </el-form-item>
        <el-form-item label="HRBP Owner">
          <el-select
            v-model="formInline.hrbpOwner"
            clearable
            filterable
            placeholder="HRBP Owner"
          >
            <el-option
              v-for="item in hrbpOwnerList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
<!--        <el-form-item label="HC Type">-->
<!--          <el-select-->
<!--            v-model="formInline.hcType"-->
<!--            clearable-->
<!--            filterable-->
<!--            placeholder="HC Nature"-->
<!--          >-->
<!--            <el-option-->
<!--              v-for="item in hcTypeList"-->
<!--              :key="item.value"-->
<!--              :label="item.label"-->
<!--              :value="item.value"-->
<!--            >-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item label="HC Nature">
          <el-select
            v-model="formInline.hcNature"
            clearable
            filterable
            placeholder="HC Nature"
          >
            <el-option
              v-for="item in hcNatureList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="HC Sub Nature">
          <el-select
            v-model="formInline.hcSubNature"
            clearable
            filterable
            placeholder="HC Sub Nature"
          >
            <el-option
              v-for="item in hcSubNatureList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="FTE Name">
          <el-input
            v-model="formInline.fteName"
            clearable
            placeholder="FTE Name"
          >
          </el-input>
        </el-form-item>
      </el-form>
      </PocSlider>
    </PocCardTable>
    <PocCardTable>
      <div class="flexBet">
        <div class="TableButton">
          <el-dropdown
            class="content-Body-dropdown"
            @command="dropdownCommand"
            trigger="click"
          >
          <span class="el-dropdown-link">
            <svg-icon
              icon="screen"
              style="font-size: 16px; margin-right: 4px"
            />
            <span style="font-size: 12px"> {{ dropdownText }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in dropdownArr"
              :key="item.id"
              :command="dropdownObj[item].value"
              >{{ dropdownObj[item].label }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <quotaRoleButton
          :key="formKey"
          ref="quotaRoleButtonRef"
          :hc-status="dropdownValue"
          :select-val="selectVal"
          @close="search"
        />
        </div>
        <div  class="TableButton">
          <el-button
            role="exportSelect"
            v-permission
            @click="exportSelect"
            v-loading.fullscreen.lock="fullscreenLoading"
          >导出选中</el-button>
        </div>
      </div>
      <PocTable
        ref="tTableRef"
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        size="large"
        stripe
        :max-height="100000000"
        row-key="id"
        @select="handleSelectionChange"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        @row-click="rowClick"
        @select-all="selectAll"
        highlight-current-row
      >
        <el-table-column type="selection" fixed width="50" />
        <el-table-column
          label="HC ID"
          key="hcId"
          prop="hcId"
          show-overflow-tooltip
          width="230"
        >
          <template slot-scope="scope">
            <span class="textHover" @click="onChat(scope.row)">{{
              scope.row.hcId
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="item in columnConfig"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :width="item.key === 'no' ? 60 : null"
          :min-width="item.width"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="Groupe CFO通过理由"
          width="110"
          key="groupCFOApprovalReason"
        >
          <template v-slot="scope">
            <el-tooltip
              v-if="scope.row.groupCFOApprovalReason"
              popper-class="workorder-reason-popper"
              effect="dark"
              :content="scope.row.groupCFOApprovalReason"
              placement="top"
              :disabled="isShowTooltip">
              <div
                @mouseover="onMouseOver('groupCFOApprovalReason'+scope.row.id)"
                style="overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;">
                <span :ref="'groupCFOApprovalReason'+scope.row.id">{{ scope.row.groupCFOApprovalReason }}</span>
              </div>
            </el-tooltip>
            <span v-else></span>
          </template>

        </el-table-column>

        <el-table-column
          label="Action"
          width="100"
          v-if="dropdownValue === Approved"
          fixed="right"
          align="center"
        >
          <template slot-scope="scope">
            <svg-icon
              icon="tableUnRemake"
              class="tablePointSvgClass"
              role="restore"
              v-permission
              title="还原"
              v-if="!tableRemakeRole(scope.row)"
            />
            <svg-icon
              icon="tableRemake"
              role="restore"
              v-permission
              class="tablePointSvgClass"
              title="还原"
              v-else
              @click.native="reduction(scope.row)"
            />
            <svg-icon
              icon="tableDelete"
              role="delete"
              v-permission
              class="tablePointSvgClass"
              title="删除"
              @click.native="deleteTable(scope.row)"
            />
          </template>
        </el-table-column>
      </PocTable>
    </PocCardTable>
    <HCBloodMap ref="HCBloodMapRef" @queryList="queryApi" />
    <ReductionDialog ref="ReductionDialogRef" @close="queryApi" />
  </div>
</template>

<script>
import { TableColumn } from '@/views/quotaManagement/TableColumn'
import ContractRenew from '@/views/quotaManagement/dialog/ContractRenew'
import quotaRoleButton from '@/views/quotaManagement/components/quotaRoleButton.vue'
import { downloadAll, downloadSelect, hcInformationDelete, qutoList } from "@/api/quto";
import PocSlider from '@/components/poc-slider/poc-slider'
import HCBloodMap from '@/views/quotaManagement/dialog/HCBloodMapDialog.vue'
import ReductionDialog from '@/views/quotaManagement/dialog/ReductionDialog.vue'
import {
  Active,
  ALL,
  Approved,
  Deleted,
  Recruiting
} from '@/views/quotaManagement/contain'
import noHcDialog from '@/views/quotaManagement/dialog/NoHcDialog'
const { Message } = $PCommon
import PersonnelRequisition from '@/views/personnelRequisition/personnelRequisition'
import { getItem, removeItem } from '@/utils/storage'
import {
  exportQuota,
  getDescriptionLevel3,
  getEntityHfmCode,
  getHRBPOwnerList,
  getLevel2
} from "@/api/personnelRequisition";
import { downLoadFileByBlob } from "@/utils/util";
export default {
  name: 'quotaManagement',
  mixins: [$PCommon.TableMixin],
  components: {
    PocSlider,
    noHcDialog,
    PersonnelRequisition,
    ContractRenew,
    quotaRoleButton,
    HCBloodMap,
    ReductionDialog
  },
  data() {
    return {
      loading: false,
      isShowTooltip:false,
      dropdownText: ALL,
      dropdownValue: ALL,
      columnConfig: TableColumn,
      formKey: 0,
      fullscreenLoading: false,
      multipleSelection: [],
      dropdownObj: {
        [ALL]: {
          label: 'All',
          value: ALL,
          id: Math.random()
        },
        [Active]: {
          label: 'Active',
          value: Active,
          id: Math.random()
        },
        [Approved]: {
          label: 'Approved',
          value: Approved,
          id: Math.random()
        },
        [Recruiting]: {
          label: 'Recruiting',
          value: Recruiting,
          id: Math.random()
        },
        [Deleted]: {
          label: 'Deleted',
          value: Deleted,
          id: Math.random()
        }
      },
      ALL,
      Active,
      Deleted,
      Approved,
      Recruiting,
      tableData: [],
      formInline: {
        hcNature: '',
        hcSubNature: '',
        hrbpOwner: '',
        hcType: '',
        level2: '',
        level3: '',
        hfmCode: '',
        jobTitle: '',
        fteName: '',
      },
      hcTypeList: [
        {
          label: '原始',
          value: '1'
        },
        {
          label: '合并',
          value: '2'
        }
      ],
      hrbpOwnerList: [],
      hcNatureList: [
        {
          label: 'New Hire',
          value: 'New Hire'
        },
        {
          label: 'Replacement',
          value: 'Replacement'
        },
        {
          label: 'Transfer in',
          value: 'Transfer in'
        },
        {
          label: 'Transfer out with HC',
          value: 'Transfer out with HC'
        },
        {
          label: 'HC Delete',
          value: 'HC Delete'
        }
      ],
      hcSubNatureList: [
        {
          label: 'New Hire',
          value: 'New Hire'
        },
        {
          label: 'HC Delete',
          value: 'HC Delete'
        },
        {
          label: 'Transfer out without HC',
          value: 'Transfer out without HC'
        },
        {
          label: 'Transfer out with HC',
          value: 'Transfer out with HC'
        },
        {
          label: 'Leaver',
          value: 'Leaver'
        },
        {
          label: 'Transfer in with HC',
          value: 'Transfer in with HC'
        },
        {
          label: 'Transfer in without HC',
          value: 'Transfer in without HC'
        }
      ],
      selectVal: [],
      formStatus: '',
      dialogStatus: '',
      OutGoingId: '',
      hfmCodeList:[],
      level2List:[],
      level3List:[],
    }
  },
  beforeRouteLeave(to, from, next) {
    removeItem('quotaSelectVal__yc')
    next()
  },
  computed: {
    dropdownArr() {
      return Object.keys(this.dropdownObj) || []
    }
  },
  created() {
    let quotaTabs = getItem('quotaTabs')
    if (quotaTabs) {
      this.formInline.searchStatus = quotaTabs
    }
    this.queryApi()
    this.getFormList()
  },
  mounted() {},
  methods: {
    onMouseOver(str) {
      // 内容超出，显示文字提示内容
      let tag = this.$refs[str];
      let parentWidth = tag.parentNode.offsetWidth; // 获取元素父级可视宽度
      let contentWidth = tag.offsetWidth; // 获取元素可视宽度
      this.isShowTooltip = contentWidth <= parentWidth;
    },
    exportSelect(){
      if(this.multipleSelection.length === 0){
        this.$message.error('请选择数据导出');
        return
      }
      this.fullscreenLoading = true
      downloadSelect(this.multipleSelection).then(res=>{
        this.fullscreenLoading = false
        if(res){
          downLoadFileByBlob(res)
        }
      })
    },
    downloadQuito() {
      // const data = {
      //   ...this.formInline,
      //   hcStatus: this.dropdownValue,
      //   pageNum: this.pageConfig.pageNum,
      //   pageSize: this.pageConfig.pageSize
      // }
      downloadAll()
    },
    deleteTable(row) {
      const data = {
        id: [row.id]
      }
      hcInformationDelete(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('删除成功')
          this.queryApi()
        }
      })
    },
    reduction(val) {
      this.$refs.ReductionDialogRef.show({
        selectVal: val
      })
    },
    tableRemakeRole(val) {
      return val.hcType === '合并'
    },
    onChat(val) {
      this.$refs.HCBloodMapRef.show({
        id: val.id
      })
    },
    highlightedTableData() {
      let selectVal = this.multipleSelection
      if (selectVal) {
        this.tableData.forEach((item) => {
          let a = selectVal.find((item1) => item.id === item1)
          if (a) {
            this.$nextTick(() => {
              this.$refs.tTableRef.toggleRowSelection(item, true)
            })
          }
        })
      }
    },
    dropdownCommand(val) {
      this.dropdownText = this.dropdownObj[val].label
      this.dropdownValue = this.dropdownObj[val].value
      this.formKey++
      this.pageConfig.pageNum = 1
      this.multipleSelection = []
      this.selectVal = []
      this.queryApi()
    },
    resetButton() {
      this.formInline = {
        hcNature: '',
        hcSubNature: '',
        hrbpOwner: '',
        hcType: '',
        fteName:'',
      }
      this.dropdownValue = this.ALL
      this.dropdownText = 'All'
      this.pageConfig.pageSize = 10
      this.pageConfig.pageNum = 1
      this.selectVal = []
      this.multipleSelection = []
      this.queryApi()
    },
    search(val) {
      this.pageConfig.pageNum = 1
      this.selectVal = []
      this.multipleSelection = []
      this.queryApi()
    },
    selectAll(rows) {
      if (rows.length) {
        rows.forEach((row) => {
          if (!this.multipleSelection.find((item) => item === row.id)) {
            this.multipleSelection.push(row.id);
            this.selectVal.push(row);
          }
        });
      } else {
        this.tableData.forEach((row) => {
          this.multipleSelection = this.multipleSelection.filter(
            (item) => item !== row.id
          );
          this.selectVal = this.selectVal.filter(
            (item) => item.id !== row.id
          );
        });
      }
    },
    rowClick(val) {},
    handleSelectionChange(val,row) {
      // this.selectVal = val
      if (this.multipleSelection.find((item) => item === row.id)) {
        //下面这个filter方法就是删除的方法
        this.multipleSelection = this.multipleSelection.filter(
          (item) => item !== row.id
        );
        this.selectVal = this.selectVal.filter(
          (item) => item.id !== row.id
        );
      } else {
        this.multipleSelection.push(row.id);
        this.selectVal.push(row);
      }
    },
    changeLevel2(val){
      if(val){
        this.formInline.level3 = ''
        getDescriptionLevel3({assigmentProjectLevel2:val}).then(({code,data})=>{
          if(code === 200){
            this.level3List = data
          }
        })
      }else {
        getDescriptionLevel3().then(({code,data})=>{
          if(code === 200){
            this.level3List = data
          }
        })
      }
    },
    getFormList() {
      getEntityHfmCode().then(({code,data})=>{
        if(code === 200){
          this.hfmCodeList = data
        }
      })
      getLevel2().then(({code,data})=>{
        if(code === 200){
          this.level2List = data
        }
      })
      getDescriptionLevel3().then(({code,data})=>{
        if(code === 200){
          this.level3List = data
        }
      })
    },
    onDelete(val) {},
    queryApi() {
      this.loading = true
      const data = {
        ...this.formInline,
        hcStatus: this.dropdownValue,
        pageNum: this.pageConfig.pageNum,
        pageSize: this.pageConfig.pageSize
      }
      this.tableData = []
      qutoList(data).then((res) => {
        if (res && res.code === 200) {
          this.loading = false
          if (res.data && res.data.list) {
            this.tableData = res.data.list
            this.pageConfig.total = res.data.total
            this.highlightedTableData()

          } else {
            this.tableData = []
            this.pageConfig.total = 0
          }
        }
      })
      getHRBPOwnerList().then((res) => {
        if (res && res.code === 200) {
          this.hrbpOwnerList = res.data
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.quotaManagementBody {
  .textHover {
    font-size: 12px !important;
    font-family: ArialMT;
    &:hover {
      cursor: pointer;
      color: $--color-button-primary;
      text-decoration: underline;
    }
  }
  :deep(.titleTop) {
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #303133;
  }
  .content-Body-dropdown {
    box-sizing: border-box;
    color: $--color-button-primary;
    cursor: pointer;
    font-size: 12px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
  }
  .buttonTable {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .TableButton {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    .content-Body-dropdown {
      margin-right: 14px;
    }
  }
  .topTitle {
    display: flex;
    justify-content: space-between;
  }
}
</style>
