<template>
  <div class="HCBloodMap">
    <PGTitle title="HC合并信息" v-show="ismerge">
      <charts
        id="bloodMapCharts"
        :data-source="dataSource"
        position="bloodMap"
      />
    </PGTitle>
    <PGTitle title="分摊记录 (最近)" v-show="isHc">
      <div class="AudiDataNext__AudiDataNextTable">
        <div class="AudiDataNext__AudiDataNextTable__positionTableData">
          <div class="title">HC1</div>
          <PocTable
            :data="BloodMapData.hc1"
            :max-height="100000000"
            hidepage
            stripe
          >
            <el-table-column
              v-for="item in positionTableDataColumn"
              :key="item.key"
              :prop="item.key"
              :label="item.name"
              :fixed="item.fixed"
              :width="item.width"
              align="left"
              show-overflow-tooltip
            ></el-table-column>
          </PocTable>
        </div>
        <div class="AudiDataNext__AudiDataNextTable__positionTableData">
          <div class="title">HC2</div>
          <PocTable
            :data="BloodMapData.hc2"
            :max-height="100000000"
            hidepage
            stripe
          >
            <el-table-column
              v-for="item in positionTableDataColumn"
              :key="item.key"
              :prop="item.key"
              :label="item.name"
              :fixed="item.fixed"
              :width="item.width"
              align="left"
              show-overflow-tooltip
            >
            </el-table-column>
          </PocTable>
        </div>
      </div>
    </PGTitle>
    <el-empty v-show="!ismerge && !isHc" description="暂无数据"></el-empty>
  </div>
</template>

<script>
import PGTitle from '@/components/PG-title/PG-title.vue'
import charts from '@/views/quotaManagement/components/charts.vue'
import { getBloodHistory } from '@/api/personnelRequisition'
export default {
  name: 'HCBloodMap',
  created() {
    this.getInitDate()
  },
  props: {
    id: {
      type: String,
      require: true
    }
  },
  computed: {
    ismerge() {
      return Boolean(
        this.BloodMapData &&
          this.BloodMapData.mergeList &&
          this.BloodMapData.mergeList.length
      )
    },
    isHc() {
      return Boolean(
        this.BloodMapData &&
          this.BloodMapData.hc1 &&
          this.BloodMapData.hc1.length
      )
    }
  },
  data() {
    return {
      dataSource: {},
      BloodMapData: {},
      positionTableDataColumn: [
        {
          key: 'salary',
          name: 'Salary',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'before',
          name: '分摊前',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false,
          width: 200
        },
        {
          key: 'after',
          name: '分摊后',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false,
          width: 200
        }
      ]
    }
  },
  methods: {
    getInitDate() {
      const data = {
        id: this.id
      }
      getBloodHistory(data).then((res) => {
        if (res && res.code === 200) {
          this.BloodMapData = { ...res.data }
          this.formatData()
        }
      })
    },
    formatData(val) {
      let ds = {
        name: `<span>HC ID</span> <span class="hcFontText mergeTitle">${this.BloodMapData.newHcType}</span>`,
        title: `<span class="hcFontText">${this.BloodMapData.newHcId}</span>`,
        children: []
      }
      if (this.ismerge) {
        this.BloodMapData.mergeList.forEach((item) => {
          ds.children.push({
            ...item,
            name: `<span>HC ID</span> <span class="hcFontText targetTitle">${item.hcType}</span>`,
            title: `<span class="hcFontText">${item.hcId}</span> `
          })
        })
        this.dataSource = ds
      } else {
        this.dataSource = {}
      }
    }
  },
  components: {
    PGTitle,
    charts
  }
}
</script>

<style lang="scss" scoped>
.HCBloodMap {
  .AudiDataNext__AudiDataNextTable {
    display: flex;
    &__positionTableData {
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
      .title {
        font-size: 16px;
        margin-bottom: 2px;
      }
    }
  }
  .mergeTitle {
    width: 24px;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    background: #2490e3;
    border-radius: 2px;
    padding: 1px 3px 1px 3px;
    color: #ffffff;
  }
  .targetTitle {
    width: 24px;
    height: 17px;
    padding: 1px 3px 1px 3px;
    font-size: 12px;
    background: #6ebaaf;
    border-radius: 2px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
  }
}
</style>
