<template>
  <el-drawer
    :visible.sync="drawer"
    size="95%"
    custom-class="ApportionDialog"
    direction="btt"
    width="80%"
    :before-close="close"
    append-to-body
    destroy-on-close
  >
    <template v-slot:title>
      <div>预算分摊</div>
      <div>
        <el-button @click="close">取消</el-button>
        <el-button @click="submit" type="primary" :disabled="hcErrorTip"
          >保存</el-button
        >
      </div>
    </template>
    <div class="ApportionDialog__content">
      <div style="display: flex; align-items: end">
        <div class="AnnualTotalCost">
          <el-statistic
            group-separator=","
            :precision="2"
            :value="annualTotalCostNumber"
          ></el-statistic>
          <div class="Total">Annual Total Cost(HC1+HC2)(Local Currency)</div>
        </div>
        <div v-if="hcErrorTip" class="hcErrorTip">
          <div class="change">
            <svg-icon style="margin-right: 5px" icon="information" />超出
          </div>
          <el-statistic
            group-separator=","
            :precision="2"
            :value="annualTotalCostNumber"
          ></el-statistic>
          <span>(数据异常触发提示)</span>
        </div>
      </div>

      <div class="hcTotal">
        <div class="hcTotalItem">
          <div class="hcTotalTitle">HC1</div>
          <WageCalculation
            ref="WageCalculationRef1"
            :data-source="hc1"
            hcTotalTitle="HC1"
            @annualTotalCost="annualTotalCost"
          />
        </div>
        <div class="hcTotalItem">
          <div class="hcTotalTitle">HC2</div>
          <WageCalculation
            ref="WageCalculationRef2"
            :data-source="hc2"
            hcTotalTitle="HC2"
            @annualTotalCost="annualTotalCost1"
          />
        </div>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import WageCalculation from '@/views/quotaManagement/components/WageCalculation.vue'
import { getShareData, saveShareData } from '@/api/personnelRequisition'
export default {
  name: 'ApportionDialog',
  components: {
    WageCalculation
  },
  data() {
    return {
      drawer: false,
      annualTotalCostCount: 0,
      annualTotalCostNumber: 0,
      selectVal: [],
      hc1: {},
      hc2: {},
      cost1: 0,
      cost2: 0,
      hcErrorTip: false,
      mesArr: []
    }
  },
  methods: {
    show(val) {
      if (val) {
        this.selectVal = val.selectVal
        this.getHcData()
      }
      this.drawer = true
    },
    getHcData() {
      const data = {
        id: this.selectVal.map((item) => item.id),
        type: 'hc'
      }
      getShareData(data).then((res) => {
        if (res && res.code === 200) {
          this.annualTotalCostNumber = res.data.annualTotalCost
          this.hc1 = res.data.hc1
          this.hc2 = res.data.hc2
          // this.cost1 = res.data.hc1.annualTotalCost
          // this.cost2 = res.data.hc2.annualTotalCost
        }
      })
    },
    countCost() {
      let num = (this.cost2 + this.cost1).toFixed(2)
      this.hcErrorTip = Number(num) > this.annualTotalCostNumber
    },
    annualTotalCost(val) {
      if(val!='NaN') {
        this.cost1 = val
        this.countCost()
      }
    },
    annualTotalCost1(val) {
      if(val!='NaN') {
        this.cost2 = val
        this.countCost()
      }
    },
    close() {
      this.drawer = false
      this.annualTotalCostCount = 0
      this.cost1 = 0
      this.cost2 = 0
      this.hcErrorTip = false
    },
    submit() {
      const data = {
        annualTotalCost: this.annualTotalCostNumber,
        hc1: {
          ...this.$refs.WageCalculationRef1.formInline
        },
        hc2: {
          ...this.$refs.WageCalculationRef2.formInline
        }
      }
      saveShareData(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('分摊成功')
          this.close()
          this.$emit('close')
        }
      })
    }
  }
}
</script>

<style lang="scss">
.ApportionDialog {
  .el-drawer__header {
    margin-bottom: 16px;
    div {
      &:first-child {
        display: inline-block;
        padding-left: 19.2%;
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303133;
      }
    }
  }
  &__content {
    width: 100%;
    height: 100%;
    padding: 0 20% 50px 20%;
    .AnnualTotalCost {
      width: 568px;
      height: 56px;
      background: #f4f4f4;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 12px 16px 12px 16px;
      display: flex;
      align-items: center;
      .el-statistic {
        width: auto;
        .number {
          font-size: 24px;
          font-family: Oswald-Bold;
          font-weight: normal;
          color: #323435;
        }

        margin-right: 8px;
      }
      .Total {
        padding-top: 5px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #323435;
      }

      //&:last-child {
      //  font-size: 16px;
      //  font-family: PingFangSC-Regular, PingFang SC;
      //  font-weight: 400;
      //  color: #323435;
      //}
    }
    .hcTotal {
      display: flex;
      justify-content: space-between;
      .hcTotalTitle {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303133;
        margin-top: 12px;
        margin-bottom: 12px;
      }
    }
    .hcErrorTip {
      width: 240px;
      display: flex;
      align-items: end;
      margin-left: 12px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #de3934;
      .change {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #de3934;
      }
      .el-statistic {
        width: auto;
        .number {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #de3934;
        }
      }
    }
  }
}
</style>
