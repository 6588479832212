<template>
  <div class="WageCalculation">
    <el-form
      ref="personFormRef"
      :model="formInline"
      :rules="rules"
      label-position="top"
      class="WageCalculation-inline"
    >
      <el-form-item label="Country" prop="countryId">
        <el-select
          v-model="formInline.countryId"
          clearable
          filterable
          placeholder="请选择"
        >
          <el-option
            v-for="item in countryList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="Assigment/project（Level2）"
        prop="assigmentProjectLevel2"
      >
        <el-select
          v-model="formInline.assigmentProjectLevel2"
          clearable
          filterable
          @change="assigmentProjectLevel2Change"
          placeholder="请选择"
        >
          <el-option
            v-for="item in assigmentProjectLevel2List"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="HFM Code" prop="hfmCode">
        <el-select
          v-model="formInline.hfmCode"
          clearable
          filterable
          placeholder="请选择"
          @change="changeHfmCode"
        >
          <el-option
            v-for="item in hfmCodeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Legal Entity" prop="legalEntity">
        <el-select
          v-model="formInline.legalEntity"
          clearable
          filterable
          @change="getSocialSecurity"
          placeholder="请选择"
        >
          <el-option
            v-for="item in legalEntityList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Work location" prop="workingLocationId">
        <el-select
          v-model="formInline.workingLocationId"
          clearable
          filterable
          @change="getSocialSecurity"
          placeholder="请选择"
        >
          <el-option
            v-for="item in workingLocationIdList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="thirteenSalary"
        label="13th Salary"
        prop="thirteenSalary"
      >
        <el-switch
          v-model="formInline.thirteenSalary"
          active-color="#6EBAAF"
          inactive-color="#E36124"
        >
        </el-switch>
        <span class="switchText" v-if="formInline.thirteenSalary">是</span>
        <span class="switchText" v-else>否</span>
        <!--        <el-select-->
        <!--          v-model="formInline.thirteenSalary"-->
        <!--          clearable-->
        <!--          filterable-->
        <!--          placeholder="请选择"-->
        <!--        >-->
        <!--          <el-option-->
        <!--            v-for="item in thirteenSalaryList"-->
        <!--            :key="item.value"-->
        <!--            :label="item.label"-->
        <!--            :value="item.value"-->
        <!--          />-->
        <!--        </el-select>-->
      </el-form-item>
      <el-form-item
        label="Base Salary (Local Currency)"
        class="BaseSalary"
        prop="baseSalary"
      >
        <baseSalary
          v-model="formInline.baseSalary"
          :thirteenSalary="formInline.thirteenSalary"
          @MonthlyChange="MonthlyChange"
        />
      </el-form-item>
      <!--            -->
      <el-form-item
        :prop="!isBenefits ? 'benefits' : null"
        label="Benefits (Local Currency)"
      >
        <el-input
          v-model="formInline.benefits"
          :disabled="isBenefits"
          @input="benefitsChange"
        />
        <!--                 -->
      </el-form-item>
      <el-form-item label="Other benefits" prop="otherBenefits">
        <el-input-number
          v-model="formInline.otherBenefits"
          @change="handleChangeOtherBenefits"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="五险一金" prop="fiveInsurancesAndHousingFund">
        <el-input-number
          v-model="formInline.fiveInsurancesAndHousingFund"
          @change="handleChangefiveInsurancesAndHousingFund"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="Relocation Cost" prop="baseSalary">
        <el-input-number
          v-model="formInline.relocationCost"
          @change="handleChangeRelocationCost"
        ></el-input-number>
        <span class="yearly"><span>Yearly</span></span>
      </el-form-item>
      <el-form-item
        label="Annual Total Cost (Local Currency)"
        class="Relocation totalCost"
        prop="annualTotalCost"
      >
        <el-input-number
          v-model="formInline.annualTotalCost"
          disabled
        ></el-input-number>
        <span class="yearly"><span>Yearly</span></span>
      </el-form-item>
      <el-form-item label="Euro Conv" class="Relocation" prop="euroConv">
        <el-input-number
          v-model="formInline.euroConv"
          @change="handleChangeEuroConv"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item
        label="Annual Compensation (euros)"
        class="Relocation"
        prop="annualCompensation"
      >
        <el-input-number
          v-model="formInline.annualCompensation"
          disabled
        ></el-input-number>
        <span class="yearly"><span>Yearly</span></span>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="formInline.bill100Percent"
          >100%Billable</el-checkbox
        >
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="formInline.budgetWithinLastRf"
          >Budget within latest RF (Y/N)</el-checkbox
        >
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="formInline.hireDoesNotIncreaseCapacity"
          >Hire Does Not Increase Capacity</el-checkbox
        >
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="formInline.isEligibleForLTI"
          >Is Eligible for LTI</el-checkbox
        >
      </el-form-item>
      <el-form-item class="statistic">
        <div>
          <el-statistic
            group-separator=","
            :precision="2"
            :value="formInline.annualTotalCost"
          ></el-statistic>
        </div>
        <div>{{ hcTotalTitle }} 预算</div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  getAssigmentProjectLevel2,
  getCodeValue,
  getCountry,
  getDescriptionLevel3,
  getEntityHfmCode,
  getEuroConv,
  getLegalEntity,
  getSocialSecurityBase,
  getWorkLocation
} from '@/api/personnelRequisition'
import baseSalary from '@/views/personnelRequisition/components/BaseSalary.vue'
import { check, typeRange } from '@/utils/util'

export default {
  name: 'WageCalculation',
  components: {
    baseSalary
  },
  props: {
    dataSource: {
      type: Object
    },
    hcTotalTitle: {
      type: String,
      default: 'HC1'
    }
  },
  created() {
    this.getData()
  },
  data() {
    return {
      formInline: {
        assigmentProjectLevel2: '',
        hfmCode: '',
        fiveInsurancesAndHousingFund: 0,
        legalEntity: '',
        workingLocationId: '',
        thirteenSalary: '',
        baseSalary: '',
        id: '',
        countryId: '',
        euroConv: 0,
        benefits: 0,
        otherBenefits: 0,
        relocationCost: 0,
        annualTotalCost: 0,
        annualCompensation: 0,
        bill100Percent: '',
        budgetWithinLastRf: '',
        hireDoesNotIncreaseCapacity: '',
        isEligibleForLTI: ''
      },
      countryList: [],
      SocialSecurity: '',
      assigmentProjectLevel2List: [],
      hfmCodeList: [],
      legalEntityList: [],
      workingLocationIdList: [],
      thirteenSalaryList: [
        {
          label: '是',
          value: true
        },
        {
          label: '否',
          value: false
        }
      ],
      Monthly: '',
      rules: {}
    }
  },
  watch: {
    'formInline.annualTotalCost': {
      handler(val) {
        this.formInline.annualCompensation = (
          val * this.formInline.euroConv
        ).toFixed(2)
        this.$emit('annualTotalCost', val)
      },
      deep: true
    },
    'formInline.baseSalary': {
      handler(val) {
        this.annualCompensationChange(val)
      },
      deep: true,
      immediate: true
    },
    dataSource: {
      handler(val) {
        if (val) {
          Object.keys(this.formInline).forEach((item) => {
            this.formInline[item] = val[item]
          })
          this.annualCompensationChange(this.formInline.baseSalary)
          // this.getSocialSecurity()
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    isBenefits() {
      if (this.formInline.countryId) {
        return this.formInline.countryId === '156'
      } else {
        return true
      }
    }
  },
  methods: {
    annualCompensationChange(val) {
      this.formInline.annualCompensation = (
        val * this.formInline.euroConv
      ).toFixed(2)
      this.formInline.annualTotalCost = this.countGJ().toFixed(2)
    },
    getData() {
      getCountry().then((Country) => {
        if (Country.code && Country.code === 200) {
          this.ApiGetNum++
          if (Country.data) {
            this.countryList = Country.data
            if (
              this.formInline.countryId &&
              !this.formInline.countryId.length
            ) {
              this.formInline.countryId = '156'
            }
          } else {
            this.countryList = []
          }
        }
      })
      getEntityHfmCode().then((HfmCode) => {
        if (HfmCode.code && HfmCode.code === 200) {
          this.ApiGetNum++
          if (HfmCode.data) {
            this.hfmCodeList = HfmCode.data
          } else {
            this.hfmCodeList = []
          }
        }
      })
      getCodeValue().then((CodeValue) => {
        if (CodeValue.code && CodeValue.code === 200) {
          if (CodeValue.data) {
            this.workingLocationIdList = CodeValue.data.work_loaction
          }
        }
      })

      getEuroConv().then((EuroConv) => {
        if (EuroConv.code && EuroConv.code === 200) {
          if (EuroConv.data) {
            this.formInline.euroConv = EuroConv.data
          }
        }
      })

      getAssigmentProjectLevel2().then((InternalBrandName) => {
        if (InternalBrandName.code && InternalBrandName.code === 200) {
          if (InternalBrandName.data) {
            this.assigmentProjectLevel2List = InternalBrandName.data
          }
        }
      })
      getLegalEntity({
        hfmCode: ''
      }).then((LegalEntity) => {
        if (LegalEntity.code && LegalEntity.code === 200) {
          this.ApiGetNum++
          if (LegalEntity.data) {
            this.legalEntityList = LegalEntity.data
          }
        }
      })
      // this.loading = false
      this.loadingStatus = true
    },
    handleChangeEuroConv(val) {
      if (check(val) === '[object KeyboardEvent]') {
        let value = val.target.value
        if (value) {
          this.relocationCost = value
          this.formInline.annualCompensation = (
            Number(this.formInline.baseSalary) * Number(value)
          ).toFixed(2)
        } else {
          this.formInline.annualCompensation = 0
        }
      } else {
        this.formInline.annualCompensation = (
          Number(this.formInline.baseSalary) * Number(val)
        ).toFixed(2)
      }
    },
    benefitsChange() {
      this.formInline.annualTotalCost = this.countGJ().toFixed(2)
    },
    async MonthlyChange(val) {
      this.Monthly = val
      this.formInline.monthly = val
      console.log(12121)
      // let that = this
      // let func = function () {
      await this.getSocialSecurity('changeMoth')
      this.formInline.annualTotalCost = this.countGJ().toFixed(2)
      // }
      // throttle(func, 1000)()
    },
    async handleChangeRelocationCost(val) {
      if (check(val) === '[object KeyboardEvent]') {
        let value = val.target.value
        if (value) {
          this.relocationCost = value
          this.formInline.annualTotalCost = this.countGJ().toFixed(2)
        } else {
          this.formInline.annualTotalCost = 0
        }
      } else {
        this.formInline.annualTotalCost = this.countGJ().toFixed(2)
      }
    },
    handleChangeOtherBenefits() {
      this.formInline.annualTotalCost = this.countGJ().toFixed(2)
    },
    handleChangefiveInsurancesAndHousingFund() {
      this.formInline.annualTotalCost = this.countGJ().toFixed(2)
    },
    getUnitName(flag = false) {
      const reData = {
        hfmCode: ''
      }
      if (this.formInline.hfmCode.length) {
        reData.hfmCode = this.formInline.hfmCode
        if (flag) {
          this.formInline.businessUnitId = ''
          this.formInline.businessUnitName = ''
          this.formInline.departmentId = ''
        }
        // this.formInline.businessUnitName = ''
        // this.formInline.workingLocationId = ''
      }
      getLegalEntity(reData).then((LegalEntity) => {
        if (LegalEntity.code && LegalEntity.code === 200) {
          if (LegalEntity.data) {
            this.legalEntityList = LegalEntity.data
          }
        }
      })
    },
    changeHfmCode() {
      this.getUnitName(true)
      this.getSocialSecurity()
      this.getWorkLocation()
    },
    async assigmentProjectLevel2Change(val) {
      await this.getSocialSecurity()
      const data = {
        assigmentProjectLevel2: val
      }
      let descriptionLevel31 = await getDescriptionLevel3(data)
      if (descriptionLevel31.code && descriptionLevel31.code === 200) {
        if (descriptionLevel31.data) {
          this.descriptionLevel3List = descriptionLevel31.data
          this.formInline.descriptionLevel3 = ''
        }
      }
    },
    countGJ() {
      //  Number(this.SocialSecurity * 12) +
      return (
        Number(this.formInline.baseSalary) +
        Number(this.formInline.relocationCost) +
        Number(this.formInline.benefits) +
        Number(this.formInline.otherBenefits) +
        Number(this.formInline.fiveInsurancesAndHousingFund)
      )
    },
    getWorkLocation() {
      const data = {
        hfmCode: '',
        legalEntity: ''
      }
      let status = true
      Object.keys(data).forEach((item) => {
        if (this.formInline[item].length !== 0) {
          data[item] = this.formInline[item]
        } else {
          status = false
        }
      })
      if (status) {
        getWorkLocation(data).then((res) => {
          if (res && res.code === 200) {
            if (check(res.data) === typeRange.array) {
              if (res.data.length) {
                this.workingLocationIdList = res.data
                // this.formInline.workingLocationId = res.data[0].value
              }
            }
          }
        })
      }
    },
    async getSocialSecurity(val = '') {
      if (val !== 'changeMoth') {
        this.getWorkLocation()
      }
      let objName = {
        assigmentProjectLevel2: '',
        hfmCode: '',
        legalEntity: '',
        workingLocationId: ''
      }
      let isEmpty = Object.keys(objName).every((SocialName) => {
        return (
          this.formInline[SocialName] &&
          this.formInline[SocialName].length !== 0
        )
      })
      if (isEmpty) {
        const data = {
          assigmentProjectLevel2: this.formInline.assigmentProjectLevel2,
          hfmCode: this.formInline.hfmCode,
          legalEntity: this.formInline.legalEntity,
          workingLocationId: this.formInline.workingLocationId,
          monthly: this.Monthly,
          baseSalary: this.formInline.baseSalary
        }
        let res = await getSocialSecurityBase(data)

        if (res && res.code === 200) {
          this.formInline.fiveInsurancesAndHousingFund = res.data || 0
          this.formInline.annualTotalCost = this.countGJ().toFixed(2)
        } else {
          this.formInline.fiveInsurancesAndHousingFund = 0
          this.formInline.annualTotalCost = this.countGJ().toFixed(2)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.WageCalculation {
  width: 500px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  .el-form {
    :deep(.BaseSalary) {
      .el-input-number {
        width: 130px !important;
      }
      .year {
        width: 50px !important;
        margin-left: 10px !important;
        margin-right: 10px !important;
      }
    }
    .el-form-item {
      .el-input,
      .el-select,
      .el-input-number {
        width: 85%;
      }
      .el-form-item__content {
      }
    }
    .statistic {
      :deep(.el-form-item__content) {
        display: flex;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #323435;
        .el-statistic {
          margin-right: 10px;
          .number {
            font-size: 18px;
            font-family: Oswald-Regular_Bold, Oswald;
            font-weight: normal;
            color: #323435;
          }
          width: auto !important;
        }
      }
    }
  }
}
</style>
