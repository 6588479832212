<template>
  <el-dialog
    title="还原HC"
    :visible.sync="dialogVisible"
    width="1000px"
    custom-class="ReductionDialog"
    append-to-body
    destroy-on-close
    :before-close="close"
  >
    <div class="mergrhc">即将还原该HC</div>
    <l2rCharts :data-source="dataSource" />
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">关闭</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import l2rCharts from '@/views/quotaManagement/components/l2rCharts.vue'
import {
  getMergeData,
  getRevertMergeData,
  revertMergeData
} from '@/api/personnelRequisition'
export default {
  name: 'ReductionDialog',
  components: {
    l2rCharts
  },
  data() {
    return {
      dialogVisible: false,
      dataSource: {},
      selectVal: ''
    }
  },
  methods: {
    submit() {
      const data = {
        id: this.selectVal.id
      }
      revertMergeData(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('还原成功')
          this.$emit('close')
          this.close()
        }
      })
    },
    initData() {
      const data = {
        id: this.selectVal.id
      }
      getRevertMergeData(data).then((res) => {
        if (res && res.code === 200) {
          let ds = {
            name: ` <span>HC ID</span><span class="hcFontText mergeTitle">合并</span>`,
            title: `<span class="hcFontText">${res.data.newHcId}</span> `,
            children: []
          }
          if (res.data.mergeList) {
            res.data.mergeList.forEach((item) => {
              ds.children.push({
                ...item,
                name: `<span>HC ID</span>   <span class="hcFontText targetTitle">${item.hcType}</span> `,
                title: ` <span class="hcFontText">${item.hcId}</span> `
              })
            })
            this.dataSource = ds
          } else {
            this.dataSource = {}
          }

          // this.annualTotalCost = res.data.annualTotalCost
        }
      })
    },
    show(val) {
      if (val) {
        this.selectVal = val.selectVal
        this.initData()
      }
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
      this.dataSource = {}
      this.selectVal = []
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.ReductionDialog {
  .el-dialog__body {
    padding: 0 20px 0 20px !important;
  }
  .mergrhc {
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #545454;
    padding-bottom: 15px;
    border-bottom: 1px solid #ebeef5;
  }
  .orgchart > table:first-child {
    margin: 20px auto !important;
  }
}
</style>
