<template>
  <el-dialog
    title="Contract Renew"
    :visible.sync="dialogVisible"
    custom-class="ContractRenew"
    width="30%"
    :before-close="cancel"
  >
    <el-form
      :model="formInline"
      :rules="rules"
      ref="ContractRenewRef"
      class="ContractPosition-form"
    >
      <el-form-item
        label="Renewal Contract Period"
        prop="renewalContractPeriod"
      >
        <el-select
          v-model="formInline.renewalContractPeriod"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in renewalContractPeriodList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Renewal Notice Period" prop="renewalNoticePeriod">
        <el-select
          v-model="formInline.renewalNoticePeriod"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in renewalNoticePeriodList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="bottomButton">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="save">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { sendEmailContract } from '@/api/quto'

export default {
  name: 'ContractRenew',
  data() {
    return {
      rules: {},
      id: '',
      dialogVisible: false,
      formInline: {
        renewalContractPeriod: '',
        renewalNoticePeriod: ''
      },
      renewalContractPeriodList: [
        { label: '3 Years', value: '3 Years' },
        { label: 'Open End', value: 'Open End' }
      ],
      renewalNoticePeriodList: [
        { label: '1 Month', value: '1 Month' },
        { label: '2 Months', value: '2 Months' },
        { label: '3 Months', value: '3 Months' },
        { label: '6 Months', value: '6 Months' }
      ]
    }
  },
  methods: {
    show(data) {
      Object.keys(this.formInline).forEach((item) => {
        this.rules[item] = [
          { required: true, message: 'The field is required' }
        ]
      })
      if (data) {
        this.id = data.id
      }
      this.dialogVisible = true
    },

    save() {
      this.$refs.ContractRenewRef.validate((valid) => {
        if (valid) {
          const data = {
            id: this.id,
            ...this.formInline
          }
          sendEmailContract(data).then((res) => {
            if (res && res.code === 200) {
              this.$message.success('邮件发送成功')
              this.cancel()
            }
          })
        }
      })
    },
    cancel() {
      Object.keys(this.formInline).forEach((item) => {
        this.formInline[item] = ''
      })
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.ContractRenew {
  .bottomButton {
    text-align: right;
    padding-bottom: 10px;
  }
}
</style>
