<template>
  <el-drawer
    :visible.sync="drawer"
    size="95%"
    custom-class="HCBloodMapDialog"
    direction="btt"
    width="80%"
    :before-close="close"
    append-to-body
    destroy-on-close
  >
    <template v-slot:title>
      <div>申请详情</div>
      <div style="width: 42%">
        <el-radio-group v-model="radioValue">
          <el-radio-button label="ref">用人申请单</el-radio-button>
          <el-radio-button label="BloodMap">HC血缘</el-radio-button>
          <el-radio-button label="quto">offer审批</el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <el-button
          type="primary"
          v-if="radioValue === 'quto'"
          @click="saveOfferProposa"
          >保存</el-button
        >
        <el-button type="primary" v-if="radioValue === 'ref'" @click="saveRef"
          >保存</el-button
        >
        <el-button @click="close">返回</el-button>
      </div>
    </template>
    <div class="HCBloodMapDialog__content">
      <BloodMap v-if="radioValue === 'BloodMap'" :id="id" />
      <PersonnelForm
        v-if="radioValue === 'ref'"
        ref="PersonnelFormRefs"
        :status="PersonnelFormData.status"
        position="hcPerson"
        :form-data="PersonnelFormData.formData"
      />
      <OfferProposal
        position="quto"
        ref="financialQuotaRef"
        :id="id"
        v-if="radioValue === 'quto'"
      ></OfferProposal>
    </div>
  </el-drawer>
</template>

<script>
import { getBloodHistory } from '@/api/personnelRequisition'
import BloodMap from '@/views/quotaManagement/components/HCBloodMap.vue'
import PersonnelForm from '@/views/personnelRequisition/components/PersonnelForm.vue'
import OfferProposal from '@/views/quotaManagement/components/OfferProposal.vue'
import { saveHcInformation, saveOfferProposa } from '@/api/quto'
export default {
  name: 'HCBloodMap',
  components: {
    BloodMap,
    OfferProposal,
    PersonnelForm
  },
  data() {
    return {
      drawer: false,
      radioValue: 'BloodMap',
      id: '',
      PersonnelFormData: {
        status: 'edit',
        formData: {
          status: 'edit',
          id: ''
        }
      }
    }
  },
  methods: {
    saveOfferProposa() {
      this.$refs.financialQuotaRef.submit()
    },
    saveRef() {
      this.$refs.PersonnelFormRefs.getRef().validate((valid) => {
        if (valid) {
          const data = {
            ...this.$refs.PersonnelFormRefs.additionalInformation,
            ... JSON.parse(JSON.stringify(this.$refs.PersonnelFormRefs.formInline)),
            id: this.id
          }
          saveHcInformation(data).then((res) => {
            if (res && res.code === 200) {
              this.$message.success('保存成功')
              this.$emit('queryList')
            }
          })
        } else {
          return false
        }
      })


    },
    show(val) {
      if (val) {
        this.id = val.id
        this.PersonnelFormData.formData.id = this.id
      }
      this.drawer = true
    },
    close() {
      this.drawer = false
      this.id = ''
      this.radioValue = 'BloodMap'
      this.PersonnelFormData = {
        status: 'edit',
        formData: {
          status: 'edit',
          id: ''
        }
      }
      let bloodMapCharts = document.getElementById('bloodMapCharts')
      if (bloodMapCharts) {
        bloodMapCharts.remove()
      }
    }
  }
}
</script>

<style lang="scss">
.HCBloodMapDialog {
  .el-drawer__header {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;

    div {
      &:first-child {
        display: inline-block;
        padding-left: 18.8%;
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303133;
      }
    }
  }
  .el-drawer__header > :first-child {
    flex: none;
  }
  &__content {
    width: 100%;
    height: 100%;
    padding: 0 20% 50px 20%;
  }
}
</style>
